/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const viewportUnitsBuggyfill =
  typeof window !== "undefined" ? require("viewport-units-buggyfill") : null
viewportUnitsBuggyfill.init({ force: true })
