// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marchandise-js": () => import("./../../../src/pages/marchandise.js" /* webpackChunkName: "component---src-pages-marchandise-js" */),
  "component---src-pages-movies-js": () => import("./../../../src/pages/movies.js" /* webpackChunkName: "component---src-pages-movies-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-paintings-js": () => import("./../../../src/pages/paintings.js" /* webpackChunkName: "component---src-pages-paintings-js" */),
  "component---src-pages-workshop-js": () => import("./../../../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

